import React from 'react';

import Layout from '../../components/layouts';
import PageHeader from '../../components/PageHeader';
import Posts from '../../containers/Posts';
import SiteTitle from '../../containers/SiteTitle';

const PostsIndex = ({ location }) => (
  <Layout location={location}>
    <SiteTitle title="Posts" />
    <PageHeader header="Posts" />
    <Posts />
  </Layout>
);

export default PostsIndex;
